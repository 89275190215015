<template>
    <div class="about">
        <div class="about-tips">
            <div class="tips-text">What is SOYEGO?</div>
        </div>
        <div class="about-banner">
            <el-image :src="require('@/assets/images/index/component/new/banner.png')" fit="cover"></el-image>
            <div class="banner-logo">
                <el-image :src="require('@/assets/images/index/component/new/logo.png')" fit="cover"></el-image>
            </div>
        </div>
        <div class="about-introduce">
            <div class="introduce-container">
                <div class="introduce-title">
                    <span>About soyego</span>
                </div>
                <div class="introduce-text">
                    <div class="text-container">
                        <span>SOYEGO is founded by a group of designers from Istituto Marangoni in Milan. Not satisfied with the typical fast fashion offerings from traditional and new online retailers, we want to offer something different, a cutting-edge fashionable product that is crafted with quality. We put very strong emphasis in our quality.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-fashion">
            <div class="fashion-container">
                <div class="fashion-title">
                    <span>Craftsmanship infast fashion</span>
                </div>
                <div class="fashion-text">
                    <span>Craftmanship and fast fashion don’t intertwine. In SOYEGO, we make it happen. Our factories have previously supplied clothing to the top Italian fashion brands. We use fabrics the same fabrics as what those top brands use. Please don’t compare us with your typial online fast fashion brands. All products are checked before shipment by our Quality Control team with rigorous protocols.</span>   
                </div>
                <div class="fashion-imageList">
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/fashion1.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/fashion2.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/fashion3.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/fashion4.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/fashion5.png')" fit="cover"></el-image>
                </div>
            </div>
        </div>
        <div class="about-Ourmission">
            <div class="Ourmission-container">
                <div class="Ourmission-title">Our mission</div>
                <div class="Ourmission-text">Our team wants to bring the best product to you without breaking the bank. We want to be in the forefront of fashion. We don’t want to be a follower. 1,000+ new arrivals are introduced to our store every week. Join us! It’s time to treat yourself with a better product.</div>
                <div class="Ourmission-imageList">
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/mission1.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/mission2.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/mission3.png')" fit="cover"></el-image>
                    <el-image class="image-item" :src="require('@/assets/images/index/component/new/mission4.png')" fit="cover"></el-image>
                </div>
            </div>
        </div>
        <div class="about-model">
            <div class="model-container">
                <div class="model-left">
                    <div class="left-one">
                        <el-image class="image-item" :src="require('@/assets/images/index/component/new/model1.png')" fit="cover"></el-image>
                    </div>
                    <div class="left-two">
                        <el-image class="image-item" :src="require('@/assets/images/index/component/new/model2.png')" fit="cover"></el-image>
                    </div>
                    <div class="left-mask"></div>
                </div>
                <div class="model-right">
                    <div class="right-title">SUSTAINABLE MODEL</div>
                    <div class="right-text">We follow a “zero” waste business model have. Starting with production, we have on demand production capability where production supply matches the demand for the product. Little, close to no products are wasted during production. For return products, we have partnered with recycling companies to reuse the garments. We have also donated to The Salvation Army with our defected clothes.</div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: "about",
    components: {},
}
</script>

<style lang="scss" scoped>
// @import "./css/about.scss";
.about{
    width: 100%;
    .about-tips{
        width: 100%;
        height: calc(100vw * 70 / 1920);
        background-color: rgba(229, 130, 114, 1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tips-text{
            font-size: 20px;
            line-height: 20px;
            font-family: Poppins-Regular;
            color: rgba(255, 255, 255, 1);
        }
    }
    .about-banner{
        width: 100%;
        height: calc(100vw * 683 / 1920);
        position: relative;
        .el-image{
            width: 100%;
            height: 100%;
        }
        .banner-logo{
            width: 9.94vw;
            height: calc(9.94vw * 189 / 194);
            position: absolute;
            left: calc(50% - 9.94vw / 2);
            top: calc(50% - 9.94vw * 189 / 194);
            .el-image{
                width: 100%;
                height: 100%;
            }
        }
    }
    .about-introduce{
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #FBFAF6;
        .introduce-container{
            width: 77.08vw;
            max-width: 1920px;
            display: flex;
            flex-direction: column;
            padding: 65px 0px 80px;
            .introduce-title{
                display: flex;
                justify-content: center;
                span{
                    overflow-wrap: break-word;
                    color: rgba(51, 0, 0, 1);
                    font-size: 44px;
                    font-family: PingFang-SC-Heavy;
                    line-height: 100px;
                }
            }
            .introduce-text{
                margin-top: 30px;
                display: flex;
                justify-content: center;
                .text-container{
                    // max-width: 1440px;
                    span{
                        overflow-wrap: break-word;
                        color: rgba(51, 51, 51, 1);
                        font-size: 22px;
                        font-family: Poppins-Regular;
                        font-weight: NaN;
                        text-align: center;
                        line-height: 44px;
                    }
                }
                
            }
        }
    }
    .about-fashion{
        width: 100%;
        display: flex;
        justify-content: center;
        .fashion-container{
            width: 77.08vw;
            max-width: 1920px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 65px 0px 80px;
            .fashion-title{
                display: flex;
                justify-content: center;
                span{
                    overflow-wrap: break-word;
                    color: rgba(51, 0, 0, 1);
                    font-size: 44px;
                    font-family: PingFang-SC-Heavy;
                    line-height: 100px;
                }
            }
            .fashion-text{
                display: flex;
                justify-content: center;
                margin-top: 30px;
                span{
                    overflow-wrap: break-word;
                    color: rgba(51, 51, 51, 1);
                    font-size: 22px;
                    font-family: Poppins-Regular;
                    line-height: 44px;
                }
            }
            .fashion-imageList{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
                .image-item{
                    width: 14.58vw;
                    height: calc(14.58vw * 280 / 280);
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .about-Ourmission{
        width: 100%;
        display: flex;
        justify-content: center;
        .Ourmission-container{
            width: 77.08vw;
            max-width: 1920px;
            padding: 65px 0px 80px;
            display: flex;
            flex-direction: column;
            .Ourmission-title{
                text-align: center;
                overflow-wrap: break-word;
                color: rgba(51, 0, 0, 1);
                font-size: 44px;
                font-family: PingFang-SC-Heavy;
                line-height: 100px;
            }
            .Ourmission-text{
                overflow-wrap: break-word;
                color: rgba(51, 51, 51, 1);
                font-size: 22px;
                font-family: Poppins-Regular;
                line-height: 44px;
            }
            .Ourmission-imageList{
                display: flex;
                justify-content: space-between;
                padding: 50px 0px;
                .image-item{
                    width: 18.48vw;
                    height: calc(18.48vw * 355 / 355);
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .about-model{
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #FBFAF6;
        .model-container{
            width: 78.54vw;
            max-width: 1920px;
            padding: 116px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .model-left{
                width: 35.67vw;
                display: flex;
                justify-content: space-between;
                position: relative;
                .left-one{
                    width: 20.72vw;
                    height: calc(20.72vw * 599 / 398);
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .left-two{
                    width: 13.28vw;
                    height: calc(13.28vw * 599 / 255);
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .left-mask{
                    width: 21.56vw;
                    height: calc(21.56vw * 802 / 414);
                    background-color: #000;
                    position: absolute;
                    right: 0;
                    top: calc(-21.56vw * 102 / 414);
                    opacity: .2;
                }
            }
            .model-right{
                width: 37.23vw;
                display: flex;
                flex-direction: column;
                .right-title{
                    color: rgba(51, 0, 0, 1);
                    font-size: 48px;
                    font-weight: 500;
                }
                .right-text{
                    width: 34.89vw;
                    color: rgba(51, 51, 51, 1);
                    font-size: 20px;
                    font-family: Poppins-Regular;
                    margin-top: 60px;
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 1440px) {
    .about{
        .about-tips{
            .tips-text{
                font-size: 18px;
            }
        }
        .about-banner{

        }
        .about-introduce{
            .introduce-container{
                padding: 45px 0px 50px;
                .introduce-title{
                    span{
                        font-size: 40px;
                    }
                }
                .introduce-text{
                    margin-top: 30px;
                    .text-container{
                        span{
                            font-size: 18px;
                            line-height: 34px;
                        }
                    }
                }
            }
        }
        .about-fashion{
            .fashion-container{
                padding: 45px 0px 50px;
                .fashion-title{
                    span{
                        font-size: 40px;
                        line-height: 100px;
                    }
                }
                .fashion-text{
                    span{
                        font-size: 18px;
                        line-height: 34px;
                    }
                }
                .fashion-imageList{

                }
            }
        }
        .about-Ourmission{
            .Ourmission-container{
                padding: 45px 0px 50px;
                .Ourmission-title{
                    font-size: 40px;
                    font-family: PingFang-SC-Heavy;
                    line-height: 100px;
                }
                .Ourmission-text{
                    font-size: 18px;
                    font-family: Poppins-Regular;
                    line-height: 34px;
                }
                .Ourmission-imageList{
                    padding: 50px 0px;
                }
            }
        }
        .about-model{
            .model-container{
                padding: 116px 0px;
                .model-left{

                }
                .model-right{
                    .right-title{
                        font-size: 40px;
                        font-weight: 500;
                    }
                    .right-text{
                        font-size: 18px;
                        font-family: Poppins-Regular;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@media (max-width: 1140px) {
    .about-tips{
        .tips-text{
            font-size: 18px;
            line-height: 18px;
        }
    }
    .about{
        .about-introduce{
            .introduce-container{
                padding: 45px 0px 50px;
                .introduce-title{
                    span{
                        font-size: 40px;
                    }
                }
                .introduce-text{
                    margin-top: 30px;
                    .text-container{
                        span{
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
        .about-fashion{
            .fashion-container{
                padding: 45px 0px 50px;
                .fashion-title{
                    span{
                        font-size: 40px;
                        line-height: 100px;
                    }
                }
                .fashion-text{
                    span{
                        font-size: 18px;
                        line-height: 34px;
                    }
                }
                .fashion-imageList{

                }
            }
        }
        .about-Ourmission{
            .Ourmission-container{
                padding: 45px 0px 50px;
                .Ourmission-title{
                    font-size: 40px;
                    font-family: PingFang-SC-Heavy;
                    line-height: 100px;
                }
                .Ourmission-text{
                    font-size: 18px;
                    font-family: Poppins-Regular;
                    line-height: 30px;
                }
                .Ourmission-imageList{
                    padding: 40px 0px;
                }
            }
        }
        .about-model{
            .model-container{
                .model-left{

                }
                .model-right{
                    .right-title{
                        font-size: 32px;
                        font-weight: 500;
                    }
                    .right-text{
                        font-size: 16px;
                        font-family: Poppins-Regular;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 940px) {
    .about-tips{
        .tips-text{
            font-size: 16px;
            line-height: 16px;
        }
    }
    .about{
        .about-introduce{
            .introduce-container{
                padding: 25px 0px 30px;
                .introduce-title{
                    span{
                        font-size: 40px;
                    }
                }
                .introduce-text{
                    margin-top: 20px;
                    .text-container{
                        span{
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
        .about-fashion{
            .fashion-container{
                padding: 25px 0px 30px;
                .fashion-title{
                    span{
                        font-size: 36px;
                        line-height: 80px;
                    }
                }
                .fashion-text{
                    span{
                        font-size: 16px;
                        line-height: 28px;
                    }
                }
                .fashion-imageList{

                }
            }
        }
        .about-Ourmission{
            .Ourmission-container{
                padding: 25px 0px 30px;
                .Ourmission-title{
                    font-size: 36px;
                    font-family: PingFang-SC-Heavy;
                    line-height: 80px;
                }
                .Ourmission-text{
                    font-size: 16px;
                    font-family: Poppins-Regular;
                    line-height: 28px;
                }
                .Ourmission-imageList{
                    padding: 30px 0px;
                }
            }
        }
        .about-model{
            .model-container{
                .model-left{

                }
                .model-right{
                    .right-title{
                        font-size: 24px;
                        font-weight: 500;
                    }
                    .right-text{
                        font-size: 14px;
                        font-family: Poppins-Regular;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    
}
</style>